<template>
  <Header title="اتصل بنا" link="اتصل بنا" />
  <div class="relative z-30 -top-20 w-full">
    <div
      class="container md:w-3/4 mx-auto py-12 px-5 md:px-8 shadow-lg rounded-lg bg-white"
    >
      <h3
        class="text-black font-semibold text-xl tracking-wide leading-relaxed"
      >
        تواصل معنا
      </h3>
      <p class="text-gray tracking-wide leading-relaxed mt-4">
        رجاء لاتتردد في التواصل معنا
      </p>
      <div
        class="flex items-center justify-around flex-wrap md:flex-nowrap tracking-wide leading-relaxed text-md mx-auto my-12 text-black text-right container"
      >
        <div class="w-full md:w-auto mx-auto md:mx-0 my-4">
          <label class="font-bold">العنوان __</label>
          <p class="my-2 text-lightGray px-2 border-r-4 border-primary">
            النوفليين - طرابلس ، ليبيا
          </p>
        </div>
        <div class="w-full md:w-auto mx-auto md:mx-0 my-4">
          <label class="font-bold">البريد الإلكتروني __</label>
          <p class="my-2 text-lightGray px-2 border-r-4 border-primary">
            info@zeaan.com
          </p>
        </div>
        <div class="w-full md:w-auto mx-auto md:mx-0 my-4">
          <label class="font-bold">رقم الهاتف __</label>
          <p class="my-2 text-lightGray px-2 border-r-4 border-primary">
            00218912507632
          </p>
        </div>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-10">
        <form @submit.prevent="submit" class="w-full text-right text-black">
          <div class="w-full my-8">
            <label class="block w-full" for="name">الاسم</label>
            <input
              class="block w-full transition-all duration-500 py-2 outline-none focus:outline-none border-b"
              id="name"
              :class="
                v$.form.name.$error
                  ? 'border-red-500'
                  : 'border-infoGray focus:border-primary'
              "
              @blur="v$.form.name.$touch()"
              type="text"
              v-model="form.name"
            />
            <span
              v-if="v$.form.name.$error"
              class="text-red-500 block my-2 font-medium leading-relaxed"
            >
              <slot> هذا الحقل لايمكن أن يكون فارغاً </slot>
            </span>
          </div>
          <div class="w-full my-8">
            <label class="block w-full" for="name">البريد الإلكتروني</label>
            <input
              class="block w-full transition-all duration-500 py-2 outline-none focus:outline-none border-b"
              id="email"
              type="email"
              :class="
                v$.form.email.$error
                  ? 'border-red-500'
                  : 'border-infoGray focus:border-primary'
              "
              placeholder="example@gmail.com"
              v-model="form.email"
              @blur="v$.form.email.$touch()"
            />
            <span
              v-if="v$.form.email.$error && v$.form.email.required.$invalid"
              class="text-red-500 block my-2 font-medium leading-relaxed"
            >
              <slot> هذا الحقل لايمكن أن يكون فارغاً </slot>
            </span>
            <span
              v-if="v$.form.email.email.$invalid"
              class="text-red-500 block my-2 font-medium leading-relaxed"
            >
              <slot> صيغة البريد الإلكتروني غير صحيحة </slot>
            </span>
          </div>
          <div class="w-full my-8">
            <label class="block w-full" for="name">الرسالة</label>
            <textarea
              class="block w-full transition-all duration-500 py-2 outline-none focus:outline-none border-b"
              id="message"
              :class="
                v$.form.message.$error
                  ? 'border-red-500'
                  : 'border-infoGray focus:border-primary'
              "
              placeholder="تفاصيل عن استفسارك"
              v-model="form.message"
            ></textarea>
            <span
              v-if="v$.form.message.$error"
              class="text-red-500 block my-2 font-medium leading-relaxed"
            >
              <slot>هذا الحقل لايمكن أن يكون فارغاً </slot>
            </span>
          </div>
          <div class="w-full mx-auto my-8 flex justify-center flex-wrap">
            <div id="recaptcha_btn"></div>
            <p
              v-if="!notRobot && confirmRobot"
              @click="confirmRobot = ''"
              class="text-red-500 border-red-200 border bg-red-50 rounded-md py-2 tracking-tighter leading-relaxed mt-3 w-full flex justify-center font-medium cursor-pointer"
            >
              {{ confirmRobot }}
            </p>
          </div>
          <button
            v-if="!success"
            @click.prevent="submit"
            :disabled="loading"
            class="flex items-center focus:outline-none py-2 my-8 text-black font-bold text-xl w-52 text-center"
          >
            <span class="mx-2" :class="loading ? 'opacity-50 w-full' : null">{{
              loading ? "جاري الارسال..." : "إرسال"
            }}</span>
            <img
              :class="loading ? 'transform-left' : null"
              class="transform transition-all duration-500 hover:-translate-x-4"
              src="../assets/long-arrow-pointing-to-the-right.svg"
              alt=""
            />
          </button>
          <p v-else class="text-lg text-green-600">
            <span> تم الارسال بنجاح</span>
            <i class="bx bx-check-double mx-2"></i>
          </p>
        </form>
        <div id="map" class="w-full h-80 md:h-full"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineAsyncComponent } from "vue";
import axios from "axios";
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import mapboxgl from "mapbox-gl";

const Header = defineAsyncComponent(() => import("../components/Header"));

export default {
  name: "Contact",
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      loading: false,
      form: {
        name: "",
        email: "",
        message: "",
      },
      success: false,
      errMsg: "",
      notRobot: false,
      confirmRobot: "",
    };
  },
  validations() {
    return {
      form: {
        name: { required },
        email: { required, email },
        message: { required },
      },
    };
  },
  components: {
    Header,
  },
  methods: {
    verifyCallback() {
      this.notRobot = true;
      this.confirmRobot = null;
    },
    submit() {
      this.v$.form.$touch();
      if (this.v$.form.$error) return;
      if (this.notRobot) {
        this.loading = true;
        axios
          .post("http://api.zeaan.com/api/contactUs", {
            ...this.form,
          })
          .then(() => {
            this.loading = true;
            this.success = true;
            this.form = {
              name: "",
              email: "",
              message: "",
            };
          })
          .catch(() => {
            this.loading = true;
          });
        this.v$.form.$reset();
      } else {
        this.confirmRobot = "الرجاء التأكيد بأنك لست روبوت";
        this.v$.form.$reset();
      }
    },
  },
  async mounted() {
    /* eslint-disable */
    await grecaptcha.render("recaptcha_btn", {
      sitekey: "6LeCmLoaAAAAACim0uGUdBWTkXIh_q0sKB42VzlR",
      callback: this.verifyCallback,
    });

    const map = new mapboxgl.Map({
      container: "map", // container ID
      style: "mapbox://styles/mapbox/streets-v11", // style URL
      center: [13.180161, 32.875353], // starting position [lng, lat]
      zoom: 10, // starting zoom
    });
    map.scrollZoom.disable();
    // map.on('load', () => {
    // 	new mapboxgl.Marker().setLngLat([13.138538942209182, 32.86684281564928]).addTo(map);
    // });
  },
};
</script>
